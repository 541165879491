export const categoryColumn: any[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
  },
];
export const ScrumbledSentancColumn: any[] = [
  {
    title: "S no",
    dataIndex: "s_no",
    key: "s_no",
  },

  {
    title: " Question",
    dataIndex: "question",
    key: "question",
  },
  {
    title: " Answer",
    dataIndex: "answer",
    key: "answer",
  },
  {
    title: " Level round id",
    dataIndex: "level_round_id",
    key: "level_round_id",
  },
  {
    title: "  Category id",
    dataIndex: "category_id",

    key: "category_id",
  },
];
export const spellCheck: any[] = [
  {
    title: "S no",
    dataIndex: "s_no",
    key: "s_no",
  },

  {
    title: " Wrong spell",
    dataIndex: "wrong_spell",
    key: "wrong_spell",
  },
  {
    title: " Meaning",
    dataIndex: "meaning",
    key: "meaning",
  },
  {
    title: " Correct spell",
    dataIndex: "correct_spell",
    key: "correct_spell",
  },
  {
    title: "Level round id",
    dataIndex: "level_round_id",
    key: "level_round_id",
  },
];

export const registrationsColumn: any[] = [
  {
    title: "Register num",
    dataIndex: "register_num",
    key: "register_num",
  },
  {
    title: "User module id",
    dataIndex: "user_module_id",
    key: "user_module_id",
  },
  {
    title: "Cat id",
    dataIndex: "cat_id",
    key: "cat_id",
  },
  {
    title: "Created at",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Updated_at",
    dataIndex: "updated_at",
    key: "updated_at",
  },
];
export const schoolLevelColumn: any[] = [
  {
    title: "s no",
    dataIndex: "s_no",
    key: "s_no",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Phonetic",
    dataIndex: "phonetic",
    key: "phonetic",
  },
  {
    title: "Syllable",
    dataIndex: "syllable",
    key: "syllable",
  },
  {
    title: "Defination",
    dataIndex: "defination",
    key: "defination",
  },
  {
    title: "Example",
    dataIndex: "example",
    key: "example",
  },
  {
    title: "Hindi",
    dataIndex: "hindi",
    key: "hindi",
  },
  {
    title: "Telagu",
    dataIndex: "telagu",
    key: "telagu",
  },
  {
    title: "File_url",
    dataIndex: "file_url",
    key: "file_url",
  },
  {
    title: "Level round id",
    dataIndex: "level_round_id",
    key: "level_round_id",
  },

];

export const stateLevlColumn: any[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "Parent Category",
    dataIndex: "parent_id",
    key: "parent_id",
  },
];

export const districtColumn: any[] = [
  {
    title: "Name",
    dataIndex: "name",

    key: "name",
  },
  {
    title: "Code",
    dataIndex: "code",

    key: "code",
  },
  {
    title: "Parent Category",
    dataIndex: "parent_id",

    key: "parent_id",
  },
];

export const wordBuilding: any[] = [
  {
    title: "S no",
    dataIndex: "s_no",

    key: "s_no",
  },

  {
    title: "Word",
    dataIndex: "word",

    key: "word",
  },
  {
    title: "Words built",
    dataIndex: "words_built",

    key: "words_built",
  },
  {
    title: "Level round id",
    dataIndex: "level_round_id",

    key: "level_round_id",
  },
];

export const jumbledLettersColumn: any[] = [
  {
    title: "S no",
    dataIndex: "s_no",

    key: "s_no",
  },

  {
    title: "Jumbled word",
    dataIndex: "jumbled_word",

    key: "Jumbled_word",
  },
  {
    title: "Correct word ",
    dataIndex: "correct_word",

    key: "correct_word",
  },
  {
    title: "Level round id ",
    dataIndex: "level_round_id",

    key: "level_round_id",
  },
];

export const fillupsColumn: any[] = [
  {
    title: "S no",
    dataIndex: "s_no",

    key: "s_no",
  },
  {
    title: " Question",
    dataIndex: "question",

    key: "question",
  },
  {
    title: " Options",
    dataIndex: "options",

    key: "options",
  },
  {
    title: " Correct Ans",
    dataIndex: "correct_ans",

    key: "correct_ans",
  },
  {
    title: " Level round id",
    dataIndex: "level_round_id",

    key: "level_round_id",
  },
];

export const currectthespellingColumn: any[] = [
  {
    title: "S no",
    dataIndex: "s_no",

    key: "s_no",
  },
  {
    title: "Wrong spell",
    dataIndex: "wrong_spell",

    key: "wrong_spell",
  },
  {
    title: "Meaning",
    dataIndex: "meaning",

    key: "meaning",
  },
  {
    title: "Correct spell",
    dataIndex: "correct_spell",

    key: "correct_spell",
  },
  {
    title: "Level round id",
    dataIndex: "level_round_id",

    key: "level_round_id",
  },
];

export const wordColumn: any[] = [
  {
    title: "S no",
    dataIndex: "s_no",

    key: "s_no",
  },
  {
    title: "Name",
    dataIndex: "name",

    key: "name",
  },
  {
    title: " Phonetics",
    dataIndex: "phonetics",

    key: "phonetics",
  },
  {
    title: " Syllable",
    dataIndex: "syllable",

    key: "syllable",
  },
  {
    title: "Meaning",
    dataIndex: "meaning",

    key: "meaning",
  },
  {
    title: " Example",
    dataIndex: "example",

    key: "example",
  },
  {
    title: " Hindi",
    dataIndex: "hindi",

    key: "hindi",
  },
  {
    title: " Telugu",
    dataIndex: "telugu",

    key: "telugu",
  },
  {
    title: " File url",
    dataIndex: "file_url",

    key: "file_url",
  },
  {
    title: " Level round id",
    dataIndex: "level_round_id",

    key: "level_round_id",
  },
  {
    title: "  Category id",
    dataIndex: "category_id",

    key: "category_id",
  },
  {
    title: "Synonym",
    dataIndex: "synonym",
    key: "synonym",
  },
  {
    title: "Antonym",
    dataIndex: "antonym",
    key: "antonym",
  },
];




export const jumbledColumn: any[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "Parent Category",
    dataIndex: "parent_id",
    key: "parent_id",
  },
];
export const fillupColumn: any[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "Parent Category",
    dataIndex: "parent_id",
    key: "parent_id",
  },
];

export const wordBuildingColumn: any[] = [
  {
    title: "S no",
    dataIndex: "s_no",
    key: "s_no",
  },

  {
    title: "Word",
    dataIndex: "word",
    key: "word",
  },
  {
    title: "Words built",
    dataIndex: "words_built",
    key: "words_built",
  },
  {
    title: "Level round id",
    dataIndex: "level_round_id",
    key: "level_round_id",
  },
  {
    title: "Category id",
    dataIndex: "category_id",
    key: "category_id",
  },
];
export const HomophonesColumn: any[] = [
  {
    title: "S no",
    dataIndex: "s_no",
    key: "s_no",
  },

  {
    title: "Homophone_1",
    dataIndex: "homophone_1",
    key: "homophone_1",
  },
  {
    title: "Homophone_1_mean",
    dataIndex: "homophone_1_mean",
    key: "homophone_1_mean",
  },
  {
    title: "Homophone_2",
    dataIndex: "homophone_2",
    key: "homophone_2",
  },
  {
    title: "Homophone_2_mean",
    dataIndex: "homophone_2_mean",
    key: "homophone_2_mean",
  },
  {
    title: "Level-round-id",
    dataIndex: "level_round_id",
    key: "level_round_id",
  },
  {
    title: "Category",
    dataIndex: "category_id",
    key: "category_id",
  },
];
export const wordSubstitutionColumn: any[] = [
  {
    title: "S no",
    dataIndex: "s_no",
    key: "s_no",
  },

  {
    title: "Word",
    dataIndex: "word",
    key: "word",
  },
  {
    title: "Meaning",
    dataIndex: "meaning",
    key: "meaning",
  },
  {
    title: "Synonym",
    dataIndex: "synonym",
    key: "synonym",
  },
  {
    title: "Level round id",
    dataIndex: "level_round_id",
    key: "level_round_id",
  },

  {
    title: "Category",
    dataIndex: "category_id",
    key: "category_id",

  },
];

export const studentresultColumn: any[] = [
  {
    title: "S no",
    dataIndex: "s_no",
    key: "s_no",
  },

  {
    title: "Word",
    dataIndex: "word",
    key: "word",
  },
  {
    title: "Meaning",
    dataIndex: "meaning",
    key: "meaning",
  },
  {
    title: "Synonym",
    dataIndex: "synonym",
    key: "synonym",
  },
  {
    title: "Level round id",
    dataIndex: "level_round_id",
    key: "level_round_id",
  },

  {
    title: "Category",
    dataIndex: "category_id",
    key: "category_id",

  },
];

export const usersColumn: any[] = [
  {

    title: "name",
    dataIndex: "name",
    key: "name",
  },

  {
    title: "register_num",
    dataIndex: "register_num",
    key: "register_num",
  },
  {
    title: "classes",
    dataIndex: "classes",
    key: "classes",
  },
  {
    title: "cat_id",
    dataIndex: "cat_id",
    key: "cat_id",
  },
  {
    title: "school",
    dataIndex: "school",
    key: "school",
  },

  {
    title: "module_type",
    dataIndex: "module_type",
    key: "module_type",
  },
  {
    title: "user_id",
    dataIndex: "user_id",
    key: "user_id",
  },
];
export const PhrasalVerbsColumn: any[] = [
  {
    title: "S no",
    dataIndex: "s_no",
    key: "s_no",
  },
  {
    title: "Verb",
    dataIndex: "verb",
    key: "verb",
  },
  {
    title: "Meaning",
    dataIndex: "meaning",
    key: "meaning",
  },
  {
    title: "Example",
    dataIndex: "example",
    key: "example",
  },

  {
    title: "Level-round-id",
    dataIndex: "level_round_id",
    key: "level_round_id",
  },
  {
    title: "Category",
    dataIndex: "category_id",
    key: "category_id",
  },
];
export const CorrectSentence: any[] = [
  {
    title: "S no",
    dataIndex: "s_no",
    key: "s_no",
  },
  {
    title: "Question",
    dataIndex: "question",
    key: "question",
  },
  {
    title: "Answer",
    dataIndex: "answer",
    key: "answer",
  },



  {
    title: "Level-round-id",
    dataIndex: "level_round_id",
    key: "level_round_id",
  },
  {
    title: "Category",
    dataIndex: "category_id",
    key: "category_id",
  },
];
export const IdiomsColumn: any[] = [
  {
    title: "S no",
    dataIndex: "s_no",
    key: "s_no",
  },
  {
    title: "Word",
    dataIndex: "word",
    key: "word",
  },
  {
    title: "Meaning",
    dataIndex: "meaning",
    key: "meaning",
  },
  {
    title: "Example",
    dataIndex: "example",
    key: "example",
  },

  {
    title: "Level-round-id",
    dataIndex: "level_round_id",
    key: "level_round_id",
  },
  {
    title: "Category",
    dataIndex: "category_id",
    key: "category_id",
  },
];
