import React from "react";

import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Statistic,
  Tabs,
} from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  LineChartOutlined,
  LockOutlined,
  PieChartFilled,
  PieChartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  postDynamicApiWithEndpoint,
  set_access_token,
} from "../api/AdminServices";
import { useNavigate } from "react-router-dom";
import { CustomSider } from "../components/CustomSider";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { CustomTable } from "../components/Customtable";
import { studentresultColumn } from "../config/ColumnConstants";

export const StudentResult = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <CustomSider />
      <Layout>
        <Header
          style={{ padding: 0, background: "#fff", marginBottom: "30px" }}
        >
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            StudentResult
          </div>
        </Header>

        <Content style={{ margin: "0 16px" }}>
          <CustomTable columns={studentresultColumn} />
        </Content>

        <Footer style={{ textAlign: "center", marginTop: "30%" }}>
          VKES ©{new Date().getFullYear()} Created by QuadForTech Pvt Ltd
        </Footer>
      </Layout>
    </Layout>
  );
};
