import React from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  postDynamicApiWithEndpoint,
  set_access_token,
} from "../api/AdminServices";
import { useNavigate } from "react-router-dom";

export const SignIn = () => {
  const navigate = useNavigate();
  const onFinish = async (values: any) => {
    const response = await postDynamicApiWithEndpoint("/login", values);
    if (response.status === 200) {
      set_access_token(response?.data?.access_token);
      navigate("/dashboard");
    } else {
      alert(response?.error);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/login-back.jpg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            style={{ width: "150px" }}
            alt="logo"
          />
        </div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          style={{
            backgroundColor: "rgba(255,255,255,0.8)",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <Form.Item
            name="mobile"
            rules={[
              {
                required: true,
                message: "Please input your mobile!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="9xxxxxxxxx"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
