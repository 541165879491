import React from "react";

import {
  CorrectSentence,
  HomophonesColumn,
  IdiomsColumn,
  PhrasalVerbsColumn,
  ScrumbledSentancColumn,
  categoryColumn,
  currectthespellingColumn,
  districtColumn,
  fillupsColumn,
  jumbledLettersColumn,
  registrationsColumn,
  schoolLevelColumn,
  stateLevlColumn,
  wordBuildingColumn,
  wordColumn,
  wordSubstitutionColumn,
} from "./ColumnConstants";
import {
  CategoryFormFields,
  FillupsFormFields,
  WordFormFields,
  homophonesFormFields,
  idiomsFormFields,
  jumbledFormFields,
  phrasal_verbsFormFields,
  registrationsFormFields,
  scrambledSentenceFormFields,
  spellcheckFormFields,
  state_fillupFormFields,
  state_correct_the_given_sentence,
  word_buildingFormFields,
  wordSubstitutionFormFields,
} from "./CreateFormConstants";

export interface PageModule {
  path: string;
  name: string;
  icon: React.ReactNode;
  apiEndpoint?: string;
  columns?: any;
  formFields?: any;
}

export const pagesModule = {
  dashboard: {
    path: "/dashboard",
    name: "Dashboard",
    icon: React.createElement("DashboardOutlined"),
  },
  category: {
    path: "/category",
    name: "Category",
    icon: React.createElement("DashboardOutlined"),
    apiEndpoint: "/category",
    columns: categoryColumn,
    formFields: CategoryFormFields,
  },
  registrations: {
    path: "/registrations",
    name: "Registrations",
    icon: React.createElement("DashboardOutlined"),
    apiEndpoint: "/registrations",
    columns: registrationsColumn,
    formFields: registrationsFormFields,
  },
  words: {
    path: "/rounds/words",
    name: "Words",
    icon: React.createElement("DashboardOutlined"),
    apiEndpoint: "/words",
    columns: wordColumn,
    formFields: WordFormFields,
  },
  jumbled_letters: {
    path: "/rounds/jumbled-letters",
    name: "Jumbled Letters",
    icon: React.createElement("DashboardOutlined"),
    apiEndpoint: "/jumbled_letters",
    columns: jumbledLettersColumn,
    formFields: jumbledFormFields,
  },
  fillups: {
    path: "/rounds/fillups",
    name: "Fillups",
    icon: React.createElement("DashboardOutlined"),
    apiEndpoint: "/fillups",
    columns: fillupsColumn,
    formFields: FillupsFormFields,
  },
  correct_the_spelling: {
    path: "/rounds/correct-the-spelling",
    name: "Correct the Spelling",
    icon: React.createElement("DashboardOutlined"),
    apiEndpoint: "/spellcheck",
    columns: currectthespellingColumn,
    formFields: spellcheckFormFields,
  },

  word_building: {
    path: "/rounds/word-building",
    name: "Word Building",
    icon: React.createElement("DashboardOutlined"),
    apiEndpoint: "/word_building",
    columns: wordBuildingColumn,
    formFields: word_buildingFormFields,
  },
  scrambled_sentence: {
    path: "/rounds/scrambled-sentence",
    name: "Scrambled Sentence",
    icon: React.createElement("DashboardOutlined"),
    apiEndpoint: "/scrambled_sentences",
    columns: ScrumbledSentancColumn,
    formFields: scrambledSentenceFormFields,
  },

  homophones: {
    path: "/rounds/homophones",
    name: " Homophones",
    icon: React.createElement("DashboardOutlined"),
    apiEndpoint: "/homophones",
    columns: HomophonesColumn,
    formFields: homophonesFormFields,
  },

  state_word_sustitution: {
    path: "/rounds/word-substitution",
    name: "Word-Substitution",
    icon: React.createElement("DashboardOutlined"),
    apiEndpoint: "/word_substitution",
    columns: wordSubstitutionColumn,
    formFields: wordSubstitutionFormFields,
  },
  state_phrsal_verb: {
    path: "/rounds/phrsal-verbs",
    name: "Phrsal-Verb",
    icon: React.createElement("DashboardOutlined"),
    apiEndpoint: "/phrasal_verbs",
    columns: PhrasalVerbsColumn,
    formFields: phrasal_verbsFormFields,
  },
  state_correct_the_given_sentence: {
    path: "/rounds/correct-the-given-sentence",
    name: " Correct Sentence",
    icon: React.createElement("DashboardOutlined"),
    apiEndpoint: "/correct_sentence",
    columns: CorrectSentence,
    formFields: state_correct_the_given_sentence,
  },
  state_idioms: {
    path: "/rounds/idioms",
    name: " Idioms",
    icon: React.createElement("DashboardOutlined"),
    apiEndpoint: "/idioms",
    columns: IdiomsColumn,
    formFields: idiomsFormFields,
  },
};
