import React, { useEffect } from "react";

import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Statistic,
  Tabs,
} from "antd";

import {
  getDynamicApiWithEndpoint,
  postDynamicApiWithEndpoint,
  set_access_token,
} from "../api/AdminServices";
import { useNavigate } from "react-router-dom";
import { CustomSider } from "../components/CustomSider";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { CustomTable } from "../components/Customtable";
import { studentresultColumn, usersColumn } from "../config/ColumnConstants";

export const Users = () => {
  const items = [
    {
      key: "1",
      label: "Student",
    },
    {
      key: "2",
      label: "School",
    },
    {
      key: "3",
      label: "Guest",
    },
  ];
  const [data, setData] = React.useState<any>([]);
  useEffect(() => {
    getDynamicApiWithEndpoint("/user_module", {})
      .then((res) => {
        console.log(res);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <CustomSider />
      <Layout>
        <Header
          style={{ padding: 0, background: "#fff", marginBottom: "30px" }}
        >
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            users
          </div>
        </Header>

        <Content style={{ margin: "0 16px" }}>
          <Tabs defaultActiveKey="1" items={items} />
          <CustomTable columns={usersColumn} data={data} />
        </Content>
        <div
          style={{
            position: "absolute",
            marginTop: 20,
            right: 50,
          }}
        >
          <Button type="primary">CREATE</Button>
          <Button type="text">IMPORT</Button>
        </div>
        <Footer style={{ textAlign: "center", marginTop: "30%" }}>
          VKES ©{new Date().getFullYear()} Created by QuadForTech Pvt Ltd
        </Footer>
      </Layout>
    </Layout>
  );
};
