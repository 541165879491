import React from "react";

import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Statistic,
} from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  LineChartOutlined,
  LockOutlined,
  PieChartFilled,
  PieChartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  postDynamicApiWithEndpoint,
  set_access_token,
} from "../api/AdminServices";
import { useNavigate } from "react-router-dom";
import { CustomSider } from "../components/CustomSider";
import { Footer, Header } from "antd/es/layout/layout";

export const Dashboard = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <CustomSider />
      <Layout>
        <Header
          style={{ padding: 0, background: "#fff", marginBottom: "30px" }}
        >
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            Dashboard
          </div>
        </Header>
        <div style={{ marginBottom: 20, padding: 10 }}>
          <Row gutter={7}>
            <Col span={6}>
              <Card bordered={false}>
                <Statistic
                  title="REGISTRETIONS"
                  value={11.28}
                  precision={2}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  prefix={<ArrowUpOutlined />}
                  suffix="%"
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card bordered={false}>
                <Statistic
                  title="USERS"
                  value={9.3}
                  precision={2}
                  valueStyle={{
                    color: "#cf1322",
                  }}
                  prefix={<ArrowDownOutlined />}
                  suffix="%"
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card bordered={false}>
                <Statistic
                  title="ROUNDS"
                  value={9.3}
                  precision={2}
                  valueStyle={{
                    color: "#cf1322",
                  }}
                  prefix={<ArrowDownOutlined />}
                  suffix="%"
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card bordered={false}>
                <Statistic
                  title="LEVELS"
                  value={11.28}
                  precision={2}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                  prefix={<ArrowUpOutlined />}
                  suffix="%"
                />
              </Card>
            </Col>
          </Row>
        </div>

        <Footer style={{ textAlign: "center", marginTop: "30%" }}>
          VKES ©{new Date().getFullYear()} Created by QuadForTech Pvt Ltd
        </Footer>
      </Layout>
    </Layout>
  );
};
