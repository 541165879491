import React, { useState } from "react";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Menu,
  MenuProps,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  theme,
} from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { CustomSider } from "../components/CustomSider";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export const CustomTable = ({ columns, data, page }: any) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();

  const onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div
      style={{
        padding: 24,
        minHeight: 360,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <Space
        wrap
        style={{
          gap: 30,
        }}
      >
        Category
        <Select
          style={{
            width: 300,
            gap: 30,
          }}
          showSearch
          placeholder="Select Category"
          optionFilterProp="children"
          onChange={onChange}
          onSearch={onSearch}
          filterOption={filterOption}
          options={[
            {
              value: "Category 1",
              label: "category_1",
            },
            {
              value: "Category 2",
              label: "category_2",
            },
            {
              value: "Category 3",
              label: "category_3",
            },
          ]}
        />
        Level
        <Select
          style={{
            width: 300,
            gap: 30,
          }}
          showSearch
          placeholder="Select Level"
          optionFilterProp="children"
          onChange={onChange}
          onSearch={onSearch}
          filterOption={filterOption}
          options={[
            {
              value: "School Level",
              label: "school_level",
            },
            {
              value: " District Level",
              label: "district_level",
            },
            {
              value: "State Level",
              label: "state_level",
            },
            {
              value: "National Level",
              label: "national_level",
            },
          ]}
        />
      </Space>
      <Table
        style={{
          marginTop: 30,
        }}
        columns={[
          ...columns,
          {
            title: "Action",
            key: "action",
            onCell: (record) => {
              return {
                onClick: (event) => {
                  event.stopPropagation(); // this will avoid onRow being called
                  // go to placeUrl(routes.platformDetails, {})
                },
              };
            },
            render: (_, record) => (
              <span>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  size="small"
                  onClick={() =>
                    navigate(
                      `${page?.path}/${record.id ? "edit" : "create"}/${
                        record.id
                      }`
                    )
                  }
                />
                <Divider type="vertical" />
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => console.log(record)}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    size="small"
                  />
                </Popconfirm>
              </span>
            ),
          },
        ]}
        dataSource={data}
      />
    </div>
  );
};
