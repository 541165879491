import { BrowserRouter, useRoutes } from "react-router-dom";
import "./App.css";
import { SignIn } from "./pages/SignIn";
import { ConfigProvider } from "antd";
import CreateCategory from "./pages/CreateCategory";
import { BaseListPage } from "./pages/BaseListPage";
import { PageModule, pagesModule } from "./config/PageConstants";
import { Dashboard } from "./pages/Dashboard";

import { StudentResult } from "./pages/StudentResult";
import { Users } from "./pages/Users";
import { LevelRounds } from "./components/LevelRounds";
import CreateLevelRounds from "./pages/CreateLevelRounds";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const AppWrapper = () => {
  const customRoutes = [];

  customRoutes.push({ path: "/", element: <SignIn /> });

  customRoutes.push({ path: "/dashboard", element: <Dashboard /> });

  customRoutes.push({ path: "/users", element: <Users /> });

  customRoutes.push({ path: "/student-result", element: <StudentResult /> });

  customRoutes.push({ path: "/level_rounds", element: <LevelRounds /> });
  customRoutes.push({
    path: "/create_level_rounds",
    element: <CreateLevelRounds />,
  });

  const pages = Object.keys(pagesModule);
  pages.forEach((element) => {
    const page = pagesModule[element as keyof typeof pagesModule];
    customRoutes.push({
      path: page.path,
      element: <BaseListPage page={page} />,
    });
  });
  let routes = useRoutes(customRoutes);
  return routes;
};

function App() {
  return (
    <div className="App">
      <ConfigProvider theme={{ cssVar: true }}>
        <BrowserRouter>
          <AppWrapper />
        </BrowserRouter>
      </ConfigProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
