import React, { useState } from "react";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Layout,
  Menu,
  MenuProps,
  Modal,
  Row,
  Select,
  Table,
  theme,
} from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { CustomSider } from "../components/CustomSider";
import { useNavigate } from "react-router-dom";
import { CustomTable } from "./Customtable";
import {
  getDynamicApiWithEndpoint,
  postDynamicApiWithEndpoint,
  postDynamicFileApiWithEndpoint,
} from "../api/AdminServices";
import { toast } from "react-toastify";

export const LevelRounds = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [allDropDownValues, setAllDropDownValues] = useState({});

  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  React.useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const response = await getDynamicApiWithEndpoint("/level_rounds", {});
    if (response.status === 200) {
      setData(response?.data);
    } else {
      alert(response?.error);
    }
  };
  const columns = [
    {
      key: "cat_id",
      title: "Category Id",
      dataIndex: "cat_id",
    },
    {
      key: "is_learn",
      title: "Learn",
      dataIndex: "is_learn",
      render: (text: any) => String(text),
    },
    {
      key: "is_test",
      title: "Test",
      dataIndex: "is_test",
      render: (text: any) => String(text),
    },
    {
      key: "level_id",
      title: "Level Id",
      dataIndex: "level_id",
    },
    {
      key: "round_des",
      title: "Description",
      dataIndex: "round_des",
    },
    {
      key: "round_id",
      title: "Round Id",
      dataIndex: "round_id",
    },
    {
      key: "round_name",
      title: "Round Name",
      dataIndex: "round_name",
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
    },
  ];

  const importFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv, .xlsx, .xls";
    input.onchange = async (e: any) => {
      const file = e.target.files[0];
      if (file) {
        const response: any = await postDynamicFileApiWithEndpoint(
          "/level_rounds/import",
          { file }
        );
        if (response?.data) {
          toast.success(
            "Imported " + response?.data?.count + " records successfully"
          );
          fetchData();
        } else {
          toast.error("Something went wrong");
        }
      }
    };
    input.click();
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <CustomSider />
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: 20,
              direction: "rtl",
              fontWeight: "bold",
            }}
          >
            {"Level Rounds"}
            <div
              style={{
                position: "absolute",
                right: 50,
              }}
            >
              <Button
                type="primary"
                onClick={() => navigate("/create_level_rounds")}
              >
                CREATE
              </Button>{" "}
              <Button type="text" onClick={() => importFile()}>
                IMPORT
              </Button>
            </div>
          </div>
        </Header>

        <Content style={{ margin: "0 16px" }}>
          <CustomTable columns={columns} data={data} />
        </Content>

        <Footer style={{ textAlign: "center" }}>
          VKES ©{new Date().getFullYear()} Created by QuadForTech Pvt Ltd
        </Footer>
      </Layout>
    </Layout>
  );
};
