import React, { useState } from "react";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Layout,
  Menu,
  MenuProps,
  Modal,
  Row,
  Select,
  Table,
  theme,
} from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { CustomSider } from "../components/CustomSider";
import { useNavigate } from "react-router-dom";
import { CustomTable } from "./Customtable";
import {
  getDynamicApiWithEndpoint,
  postDynamicApiWithEndpoint,
  postDynamicFileApiWithEndpoint,
} from "../api/AdminServices";
import { toast } from "react-toastify";

export const CustomContainer = ({ pageModule }: any) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [allDropDownValues, setAllDropDownValues] = useState({});

  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setVisible(true);
    fetchAllDropDowns(pageModule);
  };

  const handleSubmit = async (values: any) => {
    console.log("Received values of form: ", values);
    const response: any = await postDynamicApiWithEndpoint(
      pageModule?.apiEndpoint,
      values
    );
    if (response.status === 200) {
      fetchData(pageModule);
      setVisible(false);
    } else {
      alert(response?.error);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  React.useEffect(() => {
    fetchData(pageModule);
  }, [pageModule]);
  const fetchData = async (pageModule: any) => {
    if (pageModule?.apiEndpoint) {
      const response = await getDynamicApiWithEndpoint(
        pageModule?.apiEndpoint,
        {}
      );
      if (response.status === 200) {
        setData(response?.data);
      } else {
        alert(response?.error);
      }
    }
  };

  const importFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv, .xlsx, .xls";
    input.onchange = async (e: any) => {
      const file = e.target.files[0];
      if (file) {
        const response: any = await postDynamicFileApiWithEndpoint(
          pageModule?.apiEndpoint + "/import",
          { file }
        );
        console.log(response);
        if (response?.data) {
          toast.success(
            "Imported " + response?.data?.count + " records successfully"
          );
          fetchData(pageModule);
        } else toast.error("Something went wrong");
      }
    };
    input.click();
  };

  const fetchAllDropDowns = async (pageModule: any) => {
    const dropdowns = pageModule?.formFields?.filter((field: any) => {
      return field?.type === "select";
    });
    if (dropdowns) {
      for (let i = 0; i < dropdowns?.length; i++) {
        const response = await getDynamicApiWithEndpoint(
          dropdowns[i]?.selectOptions?.apiEndpoint,
          {}
        );
        if (response.status === 200) {
          console.log(response?.data);
          setAllDropDownValues({
            ...allDropDownValues,
            [dropdowns[i]?.name]: response?.data,
          });
          console.log(allDropDownValues);
        }
      }
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <CustomSider />
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: 20,
              direction: "rtl",
              fontWeight: "bold",
            }}
          >
            {pageModule?.name}
            <div
              style={{
                position: "absolute",
                right: 50,
              }}
            >
              <Button type="primary" onClick={showModal}>
                CREATE
              </Button>{" "}
              <Button type="text" onClick={importFile}>
                IMPORT
              </Button>
            </div>
          </div>
        </Header>

        <Content style={{ margin: "0 16px" }}>
          <CustomTable columns={pageModule?.columns} data={data} />
        </Content>
        <Modal
          open={visible}
          onOk={form.submit}
          onCancel={handleCancel}
          closeIcon={false}
          title={"Create " + pageModule?.name}
          width={600}
        >
          <Form form={form} onFinish={handleSubmit} style={{ padding: 20 }}>
            {pageModule?.formFields?.map((field: any) => (
              <Form.Item
                label={field.label}
                rules={field.rules}
                key={field.name}
                name={field.name}
              >
                {field.type === "text" && <Input />}
                {field.type === "text_array" && (
                  <Input.TextArea
                    onChange={(e) => {
                      const value = e.target.value.split(",");
                      form.setFieldsValue({
                        [field.name]: value,
                      });
                    }}
                  />
                )}
                {field.type === "number" && <InputNumber />}
                {field.type === "boolean" && <Checkbox />}
                {field.type === "select" && (
                  <Select
                    onChange={(e) => {
                      form.setFieldsValue({
                        [field.name]: e,
                      });
                    }}
                  >
                    {allDropDownValues &&
                      (
                        allDropDownValues[field.name as keyof Object] as any
                      )?.map((option: any) => (
                        <Select.Option
                          key={option[field.selectOptions.valueKey]}
                          value={option[field.selectOptions.valueKey]}
                        >
                          {option[field.selectOptions.labelKey]}
                        </Select.Option>
                      ))}
                    {/* {allDropDownValues && allDropDownValues[field.name]?.map((option: any) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))} */}
                  </Select>
                )}
              </Form.Item>
            ))}
          </Form>
        </Modal>

        <Footer style={{ textAlign: "center" }}>
          VKES ©{new Date().getFullYear()} Created by QuadForTech Pvt Ltd
        </Footer>
      </Layout>
    </Layout>
  );
};
