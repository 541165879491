import React, { useState } from "react";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Menu,
  MenuProps,
  Row,
  Table,
  theme,
} from "antd";
import {
  DesktopOutlined,
  FileOutlined,
  LockOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import { useNavigate } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Dashboard", "/dashboard", <PieChartOutlined />),
  getItem("Users", "/users", <PieChartOutlined />),
  getItem("Category", "/category", <DesktopOutlined />),

  getItem("Registrations", "/registrations", <PieChartOutlined />),

  getItem("Rounds", "/rounds", <UserOutlined />, [
    getItem("Words", "/rounds/words"),
    getItem("Jumbled Letters", "/rounds/jumbled-letters"),
    getItem("Fillups", "/rounds/fillups"),
    getItem("Correct the Spelling", "/rounds/correct-the-spelling"),
    getItem("Words Building", "/rounds/word-building"),
    getItem("Scrambled Sentence", "/rounds/scrambled-sentence"),

    getItem("Homophones", "/rounds/homophones"),
    getItem("Word Substitution", "/rounds/word-substitution"),
    getItem("Phrsal verbs", "/rounds/phrsal-verbs"),
    getItem("Correct the given sentence", "/rounds/correct-the-given-sentence"),
    getItem("Idioms", "/rounds/idioms"),
  ]),
  getItem("Level Rounds", "/level_rounds", <PieChartOutlined />),
  // getItem("Student Result", "/student-result", <FileOutlined />),
];

export const CustomSider = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const handleMenuClick = (e: any) => {
    navigate(e.key);
  };
  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    navigate("/");
  };

  return (
    <Sider onCollapse={(value) => setCollapsed(value)}>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          style={{ width: "150px" }}
          alt="logo"
        />
      </div>
      <div
        style={{
          position: "absolute",
          left: "29%",
          bottom: 30,
        }}
      >
        <Button type="primary" onClick={() => logout()}>
          Logout
        </Button>
      </div>
      <Menu
        theme="dark"
        onClick={handleMenuClick}
        defaultSelectedKeys={["1"]}
        mode="inline"
        items={items}
      />
    </Sider>
  );
};
