import { apiService } from "./ApiServices";

export const set_access_token = async (access_token: string) => {
  await localStorage.setItem("access_token", access_token);
};

export const get_access_Token = async () => {
  const Access = await localStorage.getItem("access_token");
  return Access;
};

export const logout = async () => {
  localStorage.removeItem("user");
  localStorage.removeItem("access_token");
};

export const generateRandom = () => {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

const checkResponse = (response: Response) => {
  if (response.status === 401) {
    alert("Session Expired, Please login again");
    logout();
    window.location.reload();
  } else {
    return Promise.resolve(response);
  }
};

export const postDynamicApiWithEndpoint = async (
  apiEndpoint: any,
  body: any
) => {
  const response = await apiService({
    uri: `${process.env.REACT_APP_BASE_URL}${apiEndpoint}`,
    method: "POST",
    body: body,
    jwtToken: await get_access_Token(),
  });
  return checkResponse(response) ? response : null;
};

export const postDynamicFileApiWithEndpoint = async (
  apiEndpoint: any,
  body: any
) => {
  const formData = new FormData();
  for (let key in body) {
    formData.append(key, body[key]);
  }
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}${apiEndpoint}`,
    {
      method: "POST",
      body: formData,
    }
  );
  return response.json();
};

export const getDynamicApiWithEndpoint = async (
  apiEndpoint: any,
  body: any
) => {
  const response = await apiService({
    uri: `${process.env.REACT_APP_BASE_URL}${apiEndpoint}`,
    method: "GET",
    body: body,
    jwtToken: await get_access_Token(),
  });
  return checkResponse(response) ? response : null;
};
