export const apiService = ({ uri, method, body, jwtToken }: any) => {
  const headers = {
    "Content-Type": "application/json",
    ...(jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}),
  };
  const promise = new Promise<any>((resolve, reject) => {
    try {
      const request: RequestInit = {
        method: method,
        headers: headers,
      };
      if (method === "POST" || method === "PATCH" || method === "DELETE") {
        request.body = JSON.stringify(body);
      }
      fetch(uri, request)
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((error) => {
          console.error("catch1 " + error);
          reject(error);
        });
    } catch (msg) {
      console.error("catch2 " + msg);
      reject(msg);
    }
  });
  return promise;
};
