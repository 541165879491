import React, { useState } from "react";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Menu,
  MenuProps,
  Row,
  Table,
  theme,
} from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { CustomSider } from "../components/CustomSider";
import { CustomContainer } from "../components/CustomContainer";

export const BaseListPage = (props: any) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return <CustomContainer pageModule={props?.page} />;
};
