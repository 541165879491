import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  theme,
  Layout,
  CheckboxProps,
  Select,
} from "antd";
import { CustomSider } from "../components/CustomSider";
import { useNavigate } from "react-router-dom";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { Checkbox } from "antd";
import {
  getDynamicApiWithEndpoint,
  postDynamicApiWithEndpoint,
} from "../api/AdminServices";
import { toast } from "react-toastify";

const levelDropdownOptions = [
  { key: "SCHOOL_LEVEL", value: "SCHOOL_LEVEL" },
  { key: "STATE_LEVEL", value: "STATE_LEVEL" },
  { key: "DISTRICT_LEVEL", value: "DISTRICT_LEVEL" },
  { key: "NATIONAL_LEVEL", value: "NATIONAL_LEVEL" },
];

const CreateLevelRounds = () => {
  const [partyCategoryDropdown, setPartyCategoryDropdown] = useState([]);

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    values.round_id = parseInt(values.round_id);
    const response = await postDynamicApiWithEndpoint("/level_rounds", values);
    if (response.status === 200 && response?.data) {
      toast.success("Level Round created successfully");
      navigate("/level_rounds");
    } else {
      alert(response?.error);
    }
  };
  const [data, setData] = useState([]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const response = await getDynamicApiWithEndpoint("/category", {});
    if (response.status === 200) {
      setPartyCategoryDropdown(response?.data);
    } else {
      alert(response?.error);
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <CustomSider />
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: 20,
              direction: "rtl",
              fontWeight: "bold",
            }}
          >
            {"Level Rounds"}
          </div>
        </Header>

        <Content style={{ margin: "0 16px" }}>
          <div
            style={{
              marginTop: 20,
              marginLeft: "25%",
              width: "50%",
            }}
          >
            <Form name="category" onFinish={onFinish}>
              <Form.Item
                label="Category"
                name="cat_id"
                rules={[{ required: true }]}
              >
                <Select placeholder="Select a Category">
                  {partyCategoryDropdown &&
                    partyCategoryDropdown.map((item: any) => (
                      <Select.Option key={item?.id} value={item?.id}>
                        {item?.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Level Id"
                name="level_id"
                rules={[
                  {
                    required: true,
                    message: "Please input Level Id",
                  },
                ]}
              >
                <Select placeholder="Level Id">
                  {levelDropdownOptions.map((item: any) => (
                    <Select.Option key={item.key} value={item.value}>
                      {item.key}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Description"
                name="round_des"
                rules={[
                  {
                    required: true,
                    message: "Please input Desription",
                  },
                ]}
              >
                <Input placeholder="Description" />
              </Form.Item>
              <Form.Item
                label="Round Id"
                name="round_id"
                rules={[
                  {
                    required: true,
                    message: "Please input Round Id",
                  },
                ]}
              >
                <Input placeholder="Round Id" />
              </Form.Item>
              <Form.Item
                label="Round Name"
                name="round_name"
                rules={[
                  {
                    required: true,
                    message: "Please input Round Name",
                  },
                ]}
              >
                <Input placeholder="Round Name" />
              </Form.Item>

              <Form.Item
                label="Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please input Type",
                  },
                ]}
              >
                <Select placeholder="Select a Type">
                  <Select.Option key="TEST" value="TEST">
                    TEST
                  </Select.Option>
                  <Select.Option key="LEARN" value="LEARN">
                    LEARN
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="is_learn" valuePropName="checked">
                <Checkbox defaultChecked={false}>IS Learn</Checkbox>
              </Form.Item>

              <Form.Item name="is_test" valuePropName="checked">
                <Checkbox defaultChecked={false}>IS Test</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Content>

        <Footer style={{ textAlign: "center" }}>
          VKES ©{new Date().getFullYear()} Created by QuadForTech Pvt Ltd
        </Footer>
      </Layout>
    </Layout>
  );
};

export default CreateLevelRounds;
