import React from "react";
import { Button } from "antd";

export const CategoryFormFields = [
  {
    name: "name",
    label: "Name",
    type: "text",
  },
  {
    name: "code",
    label: "Code",
    type: "text",
  },
];
export const registrationsFormFields = [
  {
    name: "register_num",
    label: "register_num",
    type: "text",
  },
  {
    name: "user_module_id",
    label: "user module id",
    type: "text",
  },
  {
    name: "cat_id",
    label: "cat_id",
    type: "text",
  },
];

export const WordFormFields = [
  {
    name: "category_id",
    label: "category",
    type: "select",
    selectOptions: {
      apiEndpoint: "/category",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "level_round_id",
    label: "level_round_id",
    type: "select",
    selectOptions: {
      apiEndpoint: "/level_rounds",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "s_no",
    label: "S no",
    type: "number",
  },
  {
    name: "name",
    label: "name",
    type: "text",
  },
  
  {
    name: "file_url",
    label: "file_url",
    type: "text",
  },
  {
    name: "example",
    label: "example",
    type: "text",
  },
  {
    name: "meaning",
    label: "meaning",
    type: "text",
  },
  {
    name: "syllable",
    label: "syllable",
    type: "text",
  },
  {
    name: "phonetics",
    label: "phonetics",
    type: "text",
  },
  {
    name: "synonym",
    label: "synonym",
    type: "text_array",
  },
  {
    name: "antonym",
    label: "antonym",
    type: "text_array",
  },
  {
    name: "telugu",
    label: "telugu",
    type: "text",
  },
  {
    name: "hindi",
    label: "hindi",
    type: "text",
  },
];



export const spellcheckFormFields = [
  {
    name: "level_round_id",
    label: "level_round_id",
    type: "select",
    selectOptions: {
      apiEndpoint: "/level_rounds",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "category_id",
    label: "category",
    type: "select",
    selectOptions: {
      apiEndpoint: "/category",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "s_no",
    label: "s_no",
    type: "number",
  },
  {
    name: "meaning",
    label: "meaning",
    type: "text",
  },
{
    name: "correct_spell",
    label: "correct_spell",
    type: "text",
  },
  {
    name: "wrong_spell",
    label: "wrong_spell",
    type: "text",
  },
];
export const FillupsFormFields = [
  {
    name: "level_round_id",
    label: "level_round_id",
    type: "select",
    selectOptions: {
      apiEndpoint: "/level_rounds",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "category_id",
    label: "category",
    type: "select",
    selectOptions: {
      apiEndpoint: "/category",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "s_no",
    label: "S_no",
    type: "number",
  },
  {
    name: "question",
    label: "Question",
    type: "text",
  },
  {
    name: "options",
    label: "Options",
    type: "text_array",
  },
  
  {
    name: "correct_ans",
    label: "Correct_ans",
    type: "text",
  },
];
export const word_buildingFormFields = [
  {
    name: "category_id",
    label: "category",
    type: "select",
    selectOptions: {
      apiEndpoint: "/category",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "level_round_id",
    label: "level_round_id",
    type: "select",
    selectOptions: {
      apiEndpoint: "/level_rounds",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "s_no",
    label: "s_no",
    type: "number",
  },
  
  {
    name: "word",
    label: "word",
    type: "text",
  },
  {
    name: "words_built",
    label: "words_built",
    type: "text",
  },
  {
    name: "created_at",
    label: "created_at",
    type: "datetime",
  },
];
export const jumbledFormFields = [
  {
    name: "level_round_id",
    label: "level_round_id",
    type: "select",
    selectOptions: {
      apiEndpoint: "/level_rounds",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "category_id",
    label: "category",
    type: "select",
    selectOptions: {
      apiEndpoint: "/category",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "jumbled_word",
    label: "jumbled_word",
    type: "text",
  },
  {
    name: "s_no",
    label: "s_no",
    type: "number",
  },
  
  {
    name: "correct_word",
    label: "correct_word",
    type: "text",
  },
];


export const state_fillupFormFields = [
  {
    name: "level_round_id",
    label: "level_round_id",
    type: "select",
    selectOptions: {
      apiEndpoint: "/level_rounds",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "category_id",
    label: "category",
    type: "select",
    selectOptions: {
      apiEndpoint: "/category",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "s_no",
    label: "s_no",
    type: "text",
  },
  {
    name: "meaning",
    label: "meaning",
    type: "text",
  },
  {
    name: "correct_spell",
    label: "correct_spell",
    type: "text",
  },
  {
    name: "level_round_id",
    label: "level_round_id",
    type: "text",
  },
  {
    name: "wrong_spell",
    label: "wrong_spell",
    type: "text",
  },
  {
    name: "created_at",
    label: "created_at",
    type: "datetime",
  },
  {
    name: "updated_at",
    label: "updated_at",
    type: "datetime",
  },
];
export const destrict_fillupFormFields = [
  {
    name: "level_round_id",
    label: "level_round_id",
    type: "select",
    selectOptions: {
      apiEndpoint: "/level_rounds",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "category_id",
    label: "category",
    type: "select",
    selectOptions: {
      apiEndpoint: "/category",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "s_no",
    label: "s_no",
    type: "text",
  },
  {
    name: "meaning",
    label: "meaning",
    type: "text",
  },
  {
    name: "correct_spell",
    label: "correct_spell",
    type: "text",
  },
  {
    name: "level_round_id",
    label: "level_round_id",
    type: "text",
  },
  {
    name: "wrong_spell",
    label: "wrong_spell",
    type: "text",
  },
  {
    name: "created_at",
    label: "created_at",
    type: "datetime",
  },
  {
    name: "updated_at",
    label: "updated_at",
    type: "datetime",
  },
];

export const homophonesFormFields = [
  {
    name: "level_round_id",
    label: "level_round_id",
    type: "select",
    selectOptions: {
      apiEndpoint: "/level_rounds",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "category_id",
    label: "category",
    type: "select",
    selectOptions: {
      apiEndpoint: "/category",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "s_no",
    label: "s_no",
    type: "number",
  },
  {
    name: "homophone_1",
    label: "homophone_1",
    type: "text",
  },
  {
    name: "homophone_1_mean",
    label: "homophone_1_mean",
    type: "text",
  },
  {
    name: "homophone_2",
    label: "homophone_2",
    type: "text",
  },
  {
    name: "homophone_2_mean",
    label: "homophone_2_mean",
    type: "text",
  },
  
  {
    name: "created_at",
    label: "created_at",
    type: "datetime",
  },
  {
    name: "updated_at",
    label: "updated_at",
    type: "datetime",
  },
];



export const scrambledSentenceFormFields = [
  {
    name: "level_round_id",
    label: "level_round_id",
    type: "select",
    selectOptions: {
      apiEndpoint: "/level_rounds",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "category_id",
    label: "category",
    type: "select",
    selectOptions: {
      apiEndpoint: "/category",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "s_no",
    label: "s_no",
    type: "number",
  },
  {
    name: "question",
    label: "question",
    type: "text",
  },
  
  {
    name: "answer",
    label: "answer",
    type: "text",
  },
  {
    name: "created_at",
    label: "created_at",
    type: "datetime",
  },
  {
    name: "updated_at",
    label: "updated_at",
    type: "datetime",
  },
];
export const wordSubstitutionFormFields = [
  {
    name: "level_round_id",
    label: "level_round_id",
    type: "select",
    selectOptions: {
      apiEndpoint: "/level_rounds",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "category_id",
    label: "category",
    type: "select",
    selectOptions: {
      apiEndpoint: "/category",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "s_no",
    label: "s_no",
    type: "number",
  },
  {
    name: "word",
    label: "word",
    type: "text",
  },
  
  {
    name: "meaning",
    label: "meaning",
    type: "text",
  },
  
  {
    name: "synonym",
    label: "synonym",
    type: "text",
  },
  {
    name: "created_at",
    label: "created_at",
    type: "datetime",
  },
  {
    name: "updated_at",
    label: "updated_at",
    type: "datetime",
  },
  
  
];



export const state_correct_the_given_sentence = [
  {
    name: "level_round_id",
    label: "level_round_id",
    type: "select",
    selectOptions: {
      apiEndpoint: "/level_rounds",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "category_id",
    label: "category",
    type: "select",
    selectOptions: {
      apiEndpoint: "/category",
      labelKey: "name",
      valueKey: "id",
    },
  },

  {
    name: "s_no",
    label: "s_no",
    type: "number",
  },
  
  {
    name: "question",
    label: "question",
    type: "text",
  },
  
  {
    name: "answer",
    label: "answer",
    type: "text",
  },
  {
    name: "created_at",
    label: "created_at",
    type: "datetime",
  },
  {
    name: "updated_at",
    label: "updated_at",
    type: "datetime",
  },
  
];
export const phrasal_verbsFormFields = [
  {
    name: "level_round_id",
    label: "level_round_id",
    type: "select",
    selectOptions: {
      apiEndpoint: "/level_rounds",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "category_id",
    label: "category",
    type: "select",
    selectOptions: {
      apiEndpoint: "/category",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "s_no",
    label: "s_no",
    type: "number",
  },
  {
    name: "verb",
    label: "verb",
    type: "text",
  },
  {
    name: "meaning",
    label: "meaning",
    type: "text",
  },
  {
    name: "example",
    label: "example",
    type: "text",
  },

];

export const idiomsFormFields = [
  {
    name: "level_round_id",
    label: "level_round_id",
    type: "select",
    selectOptions: {
      apiEndpoint: "/level_rounds",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "category_id",
    label: "category",
    type: "select",
    selectOptions: {
      apiEndpoint: "/category",
      labelKey: "name",
      valueKey: "id",
    },
  },
  {
    name: "s_no",
    label: "s_no",
    type: "number",
  },
  {
    name: "word",
    label: "word",
    type: "text",
  },
  {
    name: "meaning",
    label: "meaning",
    type: "text",
  },
  {
    name: "example",
    label: "example",
    type: "text",
  },
  
];
